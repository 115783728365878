<template>
  <footer class="footer">
    <div class="container" :class="{ 'px-0': $vuetify.breakpoint.smAndUp }">
      <div class="footer__inner">
        <v-row no-gutters class="footer__up mt-12">
          <v-col
            cols="12"
            sm="6"
            class="footer__up-left col-lg-9 col-md-9 col-sm-9 col-12"
          >
            <div class="footer__logo">
              <img
                width="90"
                height="58px"
                :src="footerMenus[5]?.logo"
                :alt="getImageName(footerMenus[5]?.logo)" :title="getImageName(footerMenus[5]?.logo)" 

              />
            </div>
            <div
              class="footer__text"
              v-html="footerMenus.find((item) => item.block === 1)?.content"
            ></div>
          </v-col>
          <div class="footer__social">
            <div class="mr-3">
              <a :href="socials[0].url" target="_blank">
                <img
                  class="social_img"
                  src="https://admin.tumar.com//storage/filemanager/Footer/facebook_.png"
                  alt="facebook_"
                  title="facebook_"
                />
              </a>
            </div>
            <div class="mr-3">
              <a :href="socials[3].url" target="_blank">
                <img
                  class="social_img"
                  src="https://admin.tumar.com//storage/filemanager/Footer/pinterst.png"
                  alt="pinterst"
                  logo="pinterst"
                />
              </a>
            </div>
            <div class="mr-3">
              <a :href="socials[1].url" target="_blank">
                <img
                  class="social_img"
                  src="https://admin.tumar.com//storage/filemanager/Footer/instagram.png"
                  alt="instagram"
                  title="instagram"
                />
              </a>
            </div>
            <div class="mr-3">
              <a :href="socials[2].url" target="_blank">
                <img
                  class="social_img"
                  src="https://admin.tumar.com//storage/filemanager/Footer/youtube.png"
                  alt="youtube"
                  title="youtube"
                />
              </a>
            </div>
          </div>
        </v-row>
        <div class="footer__down">
          <!-- <div class="footer__contacts">
            <h2 class="footer__contacts-title">
              {{ footerMenus.find((item) => item.block === 2)?.title }}
            </h2>
            <div
              v-html="footerMenus.find((item) => item.block === 2)?.content"
            ></div>
          </div> -->
          <div class="col-12 footer__menu pa-0">
            <v-container class="pa-0">
              <v-row no-gutters style="margin: auto">
                <v-col
                  v-for="item in footerMenus
                    .filter(
                      (item) =>
                        item.block >= 2 && item.block <= 6 && item.block !== 5
                    )
                    .sort((a, b) => a.block - b.block)"
                  :key="item.id"
                  cols="6"
                  md="3"
                >
                  <span class="footer__item-title mb-4">
                    {{ item?.title }}
                  </span>
                  <div class="footer__item-links" v-html="removeTargetBlank(item?.content)"></div>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <div class="col-12 footer__copyright">
            <p class="copyright">2025 Tumar Art Group. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { getImageName } from '@/utils/utils'
const { mapState } = createNamespacedHelpers('footerMenu')
const { mapState: State_auth } = createNamespacedHelpers('auth')
export default {
  name: 'FooterComponent',

  data() {
    return {
      socials: [
        { name: 'facebook', url: 'https://www.facebook.com/tumarfelt' },
        { name: 'instagram', url: 'https://www.instagram.com/tumarfelt/' },
        {
          name: 'youtube',
          url: 'https://www.youtube.com/channel/UCWZbcaqfxfkyeBGgQcqmcfg',
        },
        {
          name: 'pinterest',
          url: 'https://www.pinterest.com/tumarkg/_created/',
        },
        {
          name: 'linkedin',
          url: 'https://www.linkedin.com/company/tumar-art-group/mycompany/',
        },
      ],
    }
  },
  methods: {
    getImageName,
  removeTargetBlank(html) {
    return html.replace(/target="_blank"/g, '');
  }
},
  computed: {
    ...mapState(['footerMenus']),
    ...State_auth(['localeLang']),
  },
}
</script>
<style lang="scss">
.footer__contacts,
.footer__down {
  ul {
    padding: 0;
    li {
      margin-bottom: 5px;
    }
  }
  li,
  a,
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.07em;
    text-transform: capitalize;
    color: #ffffff !important;
  }
  @media screen and (max-width: 576px) {
    li,
    a,
    p {
      color: #9d9d9d;
    }
  }
  span[contenteditable='false'] {
    display: none !important;
  }
}
.social_img {
  width: 30px;
}
.footer__copyright {
  justify-content: end;
}
footer .container {
  width: 980px
}
.footer__item-links p, .footer__item-links p * {
  text-transform: none !important;
}
</style>
